export const ApiResources = {
  Agent: 'agent.Agents',
  CustomerGroup: 'customer.CustomerGroups',
  CustomerMembership: 'customer.CustomerMemberships',
  Consumer: 'customer.Consumer',
  ConsumerGroup: 'customer.ConsumerGroups',
  ConsumerGoal: 'customer.ConsumerUpgradeGoal',
  ConsumerAffiliateRegistration: 'customer.ConsumerAffiliateRegistration',
  Customer: 'customer.Customers',
  Membership: 'customer.Memberships',
  CustomerProductBalance: 'customer.ProductBalance',
  Bank: 'network.BankMaster',
  Company: 'network.CompanyProfiles',
  Currency: 'network.Currency',
  Country: 'product.Country',
  Invitation: 'network.Invitations',
  ItemTaxGroup: 'network.ItemTaxGroups',
  PurchaseOrder: 'network.PurchaseOrders',
  TaxCode: 'network.TaxCodes',
  Setting: 'network.Settings',
  SaleOrder: 'network.SaleOrders',
  Frewie: 'network.Freewie',
  RequestQuotation: 'network.RequestQuotations',
  Notification: 'admin.Notifications',
  Appointment: 'service.Appointments',
  TaxGroup: 'network.TaxGroups',
  UOM: 'network.Uom',
  Transaction: 'payment.Transactions',
  Invoice: 'payment.Invoices',
  InvoiceDetail: 'payment.InvoiceDetail',
  InvoiceCancelled: 'payment.InvoiceCancelled',
  CreditNote: 'payment.CreditNotes',
  PaymentTerm: 'payment.PaymentTerms',
  Wallet: 'payment.Wallet',
  WalletTransaction: 'payment.WalletTransaction',
  ConsumerAffiliate: 'customer.ConsumerAffiliate',
  ConsumerCommission: 'customer.ConsumerCommission',
  UsageNotification: 'network.Notification',
  FrewiePlatforms: 'payment.FrewiePlatforms',
  OfferGroup: 'network.OfferGroup',
  BestSelling: 'product.BestSelling',
  MoreDirectory: 'network.MoreDirectory',
  GiftBackgroundImage: 'product.GiftBackgroundImage',
  PartnerOffer: 'network.PartnerOffer',
  Coupons: 'payment.Coupons',
  CouponTransactions: 'payment.CouponTransactions',
  CostSetup: 'product.CostSetup',
  CostTypeSetup: 'product.CostTypeSetup',
  DimensionSetup: 'product.DimensionSetup',
  Location: 'product.Location',
  LocationSetup: 'product.LocationSetup',
  LocationUnit: 'product.LocationUnit',
  Product: 'product.Product',
  Recommend: 'product.Recommend',
  MovingJournal: 'product.MovingJournal',
  ProductCategory: 'product.ProductCategory',
  ProductType: 'product.ProductType',
  FrewieProductPlan: 'product.FrewieProductPlan',
  FrewieSerialNumber: 'product.FrewieSerialNumber',
  Warranty: 'product.Warranty',
  WarrantyType: 'product.WarrantyType',
  LocationSorting: 'product.LocSortingHeader',
  Brand: 'product.Brand',
  PurchaseSetup: 'network.PurchaseSetups',
  ServiceMovingJournal: 'service.ServiceMovingJournals',
  Timeslot: 'service.Timeslots',
  Holiday: 'service.Holidays',

  FrewieUsageReport: 'network.FrewieUsageReport',

};
