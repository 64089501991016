import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';
import axios from 'axios';

export const FETCH_GIFT_BACKGROUND_IMAGE_REQUEST = 'FETCH_GIFT_BACKGROUND_IMAGE_REQUEST';
export const FETCH_GIFT_BACKGROUND_IMAGE_SUCCESS = 'FETCH_GIFT_BACKGROUND_IMAGE_SUCCESS';
export const FETCH_GIFT_BACKGROUND_IMAGE_ERROR = 'FETCH_GIFT_BACKGROUND_IMAGE_ERROR';


export const GET_GIFT_BACKGROUND_IMAGE_DETAIL_REQUEST =
    'GET_GIFT_BACKGROUND_IMAGE_DETAIL_REQUEST';
export const GET_GIFT_BACKGROUND_IMAGE_DETAIL_SUCCESS =
    'GET_GIFT_BACKGROUND_IMAGE_DETAIL_SUCCESS';
export const GET_GIFT_BACKGROUND_IMAGE_DETAIL_ERROR = 'GET_GIFT_BACKGROUND_IMAGE_DETAIL_ERROR';

export const CREATE_GIFT_BACKGROUND_IMAGE_REQUEST = 'CREATE_GIFT_BACKGROUND_IMAGE_REQUEST';
export const CREATE_GIFT_BACKGROUND_IMAGE_SUCCESS = 'CREATE_GIFT_BACKGROUND_IMAGE_SUCCESS';
export const CREATE_GIFT_BACKGROUND_IMAGE_ERROR = 'CREATE_GIFT_BACKGROUND_IMAGE_ERROR';

export const UPDATE_GIFT_BACKGROUND_IMAGE_REQUEST = 'UPDATE_GIFT_BACKGROUND_IMAGE_REQUEST';
export const UPDATE_GIFT_BACKGROUND_IMAGE_SUCCESS = 'UPDATE_GIFT_BACKGROUND_IMAGE_SUCCESS';
export const UPDATE_GIFT_BACKGROUND_IMAGE_ERROR = 'UPDATE_GIFT_BACKGROUND_IMAGE_ERROR';

export const DELETE_GIFT_BACKGROUND_IMAGE_REQUEST = 'DELETE_GIFT_BACKGROUND_IMAGE_REQUEST';
export const DELETE_GIFT_BACKGROUND_IMAGE_SUCCESS = 'DELETE_GIFT_BACKGROUND_IMAGE_SUCCESS';
export const DELETE_GIFT_BACKGROUND_IMAGE_ERROR = 'DELETE_GIFT_BACKGROUND_IMAGE_ERROR';

export const fetchGiftBackgroundImage = data => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                FETCH_GIFT_BACKGROUND_IMAGE_REQUEST,
                FETCH_GIFT_BACKGROUND_IMAGE_SUCCESS,
                FETCH_GIFT_BACKGROUND_IMAGE_ERROR,
            ],
            callAPI: () => ApiClient.post('product/GiftBackgroundImage/Search', data),
            payload: { data },
        }),
    );
};

export const getGiftBackgroundImageDetail = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                GET_GIFT_BACKGROUND_IMAGE_DETAIL_REQUEST,
                GET_GIFT_BACKGROUND_IMAGE_DETAIL_SUCCESS,
                GET_GIFT_BACKGROUND_IMAGE_DETAIL_ERROR,
            ],
            callAPI: () => ApiClient.get(`product/GiftBackgroundImage/${id}`),
            payload: { data: { id } },
        }),
    );
};

export const createGiftBackgroundImage = (data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                CREATE_GIFT_BACKGROUND_IMAGE_REQUEST,
                CREATE_GIFT_BACKGROUND_IMAGE_SUCCESS,
                CREATE_GIFT_BACKGROUND_IMAGE_ERROR,
            ],
            callAPI: () => ApiClient.post('product/GiftBackgroundImage', data),
            payload: { data, formId },
        }),
    );
};


export const updateGiftBackgroundImage = (id, data, formId) => dispatch => {
    return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
        dispatch({
            types: [
                UPDATE_GIFT_BACKGROUND_IMAGE_REQUEST,
                UPDATE_GIFT_BACKGROUND_IMAGE_SUCCESS,
                UPDATE_GIFT_BACKGROUND_IMAGE_ERROR,
            ],
            callAPI: () => ApiClient.put(`product/GiftBackgroundImage/${id}`, data),
            payload: { data: { ...data, id }, formId },
        }),
    );
};

export const deleteGiftBackgroundImage = id => dispatch => {
    return Promise.resolve().then(() =>
        dispatch({
            types: [
                DELETE_GIFT_BACKGROUND_IMAGE_REQUEST,
                DELETE_GIFT_BACKGROUND_IMAGE_SUCCESS,
                DELETE_GIFT_BACKGROUND_IMAGE_ERROR,
            ],
            callAPI: () => ApiClient.delete(`product/GiftBackgroundImage/${id}`),
            payload: { data: { id } },
        }),
    );
};

const initialState = {
    payload: {},
    data: {},
    response: {},
    error: {},
    errorMessage: '',
    successMessage: '',
};

export default function reducer(state = initialState, action) {
    const { payload, response, error, successMessage, errorMessage } = action;
    switch (action.type) {
        case FETCH_GIFT_BACKGROUND_IMAGE_REQUEST:
        case GET_GIFT_BACKGROUND_IMAGE_DETAIL_REQUEST:
        case CREATE_GIFT_BACKGROUND_IMAGE_REQUEST:
        case UPDATE_GIFT_BACKGROUND_IMAGE_REQUEST:
        case DELETE_GIFT_BACKGROUND_IMAGE_REQUEST:
            return {
                ...state,
                payload,
                response: {},
                error: {},
                successMessage: '',
                errorMessage: '',
            };

            return {
                ...state,
                data: { ...state.data, options: response.data.data },
                response,
                successMessage,
            };

        case FETCH_GIFT_BACKGROUND_IMAGE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: response.data.data.items,
                    pages: response.data.data.pageCount,
                },
                response,
                successMessage,
            };

        case GET_GIFT_BACKGROUND_IMAGE_DETAIL_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    item: response.data.data,
                },
                response,
                successMessage,
            };

        case CREATE_GIFT_BACKGROUND_IMAGE_SUCCESS:
        case UPDATE_GIFT_BACKGROUND_IMAGE_SUCCESS:
        case DELETE_GIFT_BACKGROUND_IMAGE_SUCCESS:
            return {
                ...state,
                response,
                successMessage,
            };

        case FETCH_GIFT_BACKGROUND_IMAGE_ERROR:
        case GET_GIFT_BACKGROUND_IMAGE_DETAIL_ERROR:
        case CREATE_GIFT_BACKGROUND_IMAGE_ERROR:
        case UPDATE_GIFT_BACKGROUND_IMAGE_ERROR:
        case DELETE_GIFT_BACKGROUND_IMAGE_ERROR:
            return {
                ...state,
                payload,
                error,
                errorMessage,
            };
        default:
            return state;
    }
}
