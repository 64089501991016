import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';

export const GET_CANBE_NOTIFIED_CUSTOMERS_REQUEST =
  'GET_CANBE_NOTIFIED_CUSTOMERS_REQUEST';
export const GET_CANBE_NOTIFIED_CUSTOMERS_SUCCESS =
  'GET_CANBE_NOTIFIED_CUSTOMERS_SUCCESS';
export const GET_CANBE_NOTIFIED_CUSTOMERS_ERROR =
  'GET_CANBE_NOTIFIED_CUSTOMERS_ERROR';

export const FETCH_NOTIFICATION_REQUEST = 'FETCH_NOTIFICATION_REQUEST';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_ERROR = 'FETCH_NOTIFICATION_ERROR';

export const fetchNotification = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_NOTIFICATION_REQUEST, FETCH_NOTIFICATION_SUCCESS, FETCH_NOTIFICATION_ERROR],
      callAPI: () => ApiClient.post('admin/Notifications/Search', data),
      payload: { data },
    }),
  );
};

export const sendNotification = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [
        SEND_NOTIFICATION_REQUEST,
        SEND_NOTIFICATION_SUCCESS,
        SEND_NOTIFICATION_ERROR,
      ],
      callAPI: () => ApiClient.post('admin/Notifications/Send', data),
      payload: { data, formId },
    }),
  );
};

export const getCanBeNotifiedCustomers = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GET_CANBE_NOTIFIED_CUSTOMERS_REQUEST,
        GET_CANBE_NOTIFIED_CUSTOMERS_SUCCESS,
        GET_CANBE_NOTIFIED_CUSTOMERS_ERROR,
      ],
      callAPI: () => ApiClient.get('admin/Notifications/GetCustomers'),
      payload: {},
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case SEND_NOTIFICATION_REQUEST:
    case FETCH_NOTIFICATION_REQUEST:
    case GET_CANBE_NOTIFIED_CUSTOMERS_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case GET_CANBE_NOTIFIED_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, canBeNotifiedCustomers: response.data.data },
        response,
        successMessage,
      };

    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case SEND_NOTIFICATION_ERROR:
    case FETCH_NOTIFICATION_ERROR:
    case GET_CANBE_NOTIFIED_CUSTOMERS_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
