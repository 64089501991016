import React from 'react';
import { Roles } from '../constants/roles';

import SignInPage from './pages/account/SignInPage';
import SignUpPage from './pages/account/SignUpPage';
import DistributorSignUpPage from './pages/account/DistributorSignUpPage';
import AgentSignUpPage from './pages/account/AgentSignUpPage';
import ForgotPasswordPage from './pages/account/ForgotPasswordPage';
import ResetPasswordPage from './pages/account/ResetPasswordPage';
import ConfirmEmailPage from './pages/account/ConfirmEmailPage';
import FrewiePlanList from './pages/frewie/frewie-product-plan/FrewiePlanList';
import FrewiePlanNew from './pages/frewie/frewie-product-plan/FrewiePlanNew';
import FrewiePlanEdit from './pages/frewie/frewie-product-plan/FrewiePlanEdit';
import CatalogPage from './pages/product/CatalogPage';
import PdfViewerPage from './pages/PdfViewerPage';

const ItemTaxGroupList = React.lazy(() => import('./pages/item-tax-group/ItemTaxGroupList'));
const ItemTaxGroupNew = React.lazy(() => import('./pages/item-tax-group/ItemTaxGroupNew'));
const ItemTaxGroupEdit = React.lazy(() => import('./pages/item-tax-group/ItemTaxGroupEdit'));

const CustomerGroupNew = React.lazy(() => import('./pages/customer-group/CustomerGroupNew'));
const CustomerGroupEdit = React.lazy(() => import('./pages/customer-group/CustomerGroupEdit'));

const ConsumerGroupNew = React.lazy(() => import('./pages/consumer-group/ConsumerGroupNew'));
const ConsumerGroupEdit = React.lazy(() => import('./pages/consumer-group/ConsumerGroupEdit'));

const ConsumerRegistrationEdit = React.lazy(() => import('./pages/consumer-affiliate-registration/ConsumerAffiliateRegistrationEdit'));
const ConsumerGoalEdit = React.lazy(() => import('./pages/consumer-goal/ConsumerGoalEdit'));

const CostTypeSetupList = React.lazy(() => import('./pages/cost-type-setup/CostTypeSetupList'));
const CostTypeSetupNew = React.lazy(() => import('./pages/cost-type-setup/CostTypeSetupNew'));
const CostTypeSetupEdit = React.lazy(() => import('./pages/cost-type-setup/CostTypeSetupEdit'));

const CostSetupList = React.lazy(() => import('./pages/cost-setup/CostSetupList'));
const CostSetupNew = React.lazy(() => import('./pages/cost-setup/CostSetupNew'));
const CostSetupEdit = React.lazy(() => import('./pages/cost-setup/CostSetupEdit'));

const DimensionSetupList = React.lazy(() => import('./pages/dimension-setup/DimensionSetupList'));
const DimensionSetupNew = React.lazy(() => import('./pages/dimension-setup/DimensionSetupNew'));
const DimensionSetupEdit = React.lazy(() => import('./pages/dimension-setup/DimensionSetupEdit'));

const WarrantyTypeList = React.lazy(() => import('./pages/warranty-type/WarrantyTypeList'));
const WarrantyTypeNew = React.lazy(() => import('./pages/warranty-type/WarrantyTypeNew'));
const WarrantyTypeEdit = React.lazy(() => import('./pages/warranty-type/WarrantyTypeEdit'));

const WarrantyList = React.lazy(() => import('./pages/warranty/WarrantyList'));
const WarrantyNew = React.lazy(() => import('./pages/warranty/WarrantyNew'));
const WarrantyEdit = React.lazy(() => import('./pages/warranty/WarrantyEdit'));

const LocationSetup = React.lazy(() => import('./pages/LocationSetup'));
const LocationUnitList = React.lazy(() => import('./pages/location-unit/LocationUnitList'));
const LocationUnitNew = React.lazy(() => import('./pages/location-unit/LocationUnitNew'));
const LocationUnitEdit = React.lazy(() => import('./pages/location-unit/LocationUnitEdit'));

const LocationSortingList = React.lazy(() => import('./pages/location-sorting/LocationSortingList'));
const LocationSortingNew = React.lazy(() => import('./pages/location-sorting/LocationSortingNew'));
const LocationSortingEdit = React.lazy(() => import('./pages/location-sorting/LocationSortingEdit'));

const ProductCategoryList = React.lazy(() => import('./pages/product-category/ProductCategoryList'));
const ProductCategoryNew = React.lazy(() => import('./pages/product-category/ProductCategoryNew'));
const ProductCategoryEdit = React.lazy(() => import('./pages/product-category/ProductCategoryEdit'));

const ProductTypeList = React.lazy(() => import('./pages/product-type/ProductTypeList'));
const ProductTypeNew = React.lazy(() => import('./pages/product-type/ProductTypeNew'));
const ProductTypeEdit = React.lazy(() => import('./pages/product-type/ProductTypeEdit'));

const LocationList = React.lazy(() => import('./pages/location/LocationList'));
const LocationEdit = React.lazy(() => import('./pages/location/LocationEdit'));
const BrandList = React.lazy(() => import('./pages/brand/BrandList'));
const BrandNew = React.lazy(() => import('./pages/brand/BrandNew'));
const BrandEdit = React.lazy(() => import('./pages/brand/BrandEdit'));
const ProductNew = React.lazy(() => import('./pages/product/ProductNew'));
const ProductEdit = React.lazy(() => import('./pages/product/ProductEdit'));

const MovementJournalList = React.lazy(() => import('./pages/movement-journal/MovementJournalList'));
const MovementJournalNew = React.lazy(() => import('./pages/movement-journal/MovementJournalNew'));
const MovementJournalAdjust = React.lazy(() => import('./pages/movement-journal/MovementJournalAdjust'));
const MovementJournalDetail = React.lazy(() => import('./pages/movement-journal/MovementJournalDetail'));

const PurchaseSetupList = React.lazy(() => import('./pages/purchase-setup/PurchaseSetupList'));
const PurchaseSetupEdit = React.lazy(() => import('./pages/purchase-setup/PurchaseSetupEdit'));

const PurchaseOrderList = React.lazy(() => import('./pages/purchase-order/PurchaseOrderList'));
const PurchaseOrderNew = React.lazy(() => import('./pages/purchase-order/PurchaseOrderNew'));
const PurchaseOrderEdit = React.lazy(() => import('./pages/purchase-order/PurchaseOrderEdit'));

const WalletList = React.lazy(() => import('./pages/wallet/WalletList'));

const AgentList = React.lazy(() => import('./pages/agent/AgentList'));
const AgentEdit = React.lazy(() => import('./pages/agent/AgentEdit'));
const AgentHierarchy = React.lazy(() => import('./pages/agent/AgentHierarchy'));

const TimeslotList = React.lazy(() => import('./pages/timeslot/TimeslotList'));
const TimeslotEdit = React.lazy(() => import('./pages/timeslot/TimeslotEdit'));
const TimeslotNew = React.lazy(() => import('./pages/timeslot/TimeslotNew'));

const HolidayList = React.lazy(() => import('./pages/holiday/HolidayList'));
const HolidayEdit = React.lazy(() => import('./pages/holiday/HolidayEdit'));
const HolidayNew = React.lazy(() => import('./pages/holiday/HolidayNew'));

const AppointmentConfig = React.lazy(() => import('./pages/appointment/AppointmentConfig'));
const AppointmentList = React.lazy(() => import('./pages/appointment/AppointmentList'));
const AppointmentEdit = React.lazy(() => import('./pages/appointment/AppointmentEdit'));
const AppointmentNew = React.lazy(() => import('./pages/appointment/AppointmentNew'));

const MembershipList = React.lazy(() => import('./pages/membership/MembershipList'));
const MembershipEdit = React.lazy(() => import('./pages/membership/MembershipEdit'));
const MembershipNew = React.lazy(() => import('./pages/membership/MembershipNew'));

const TransactionList = React.lazy(() => import('./pages/transaction/TransactionList'));
const TransactionEdit = React.lazy(() => import('./pages/transaction/TransactionEdit'));
const TransactionNew = React.lazy(() => import('./pages/transaction/TransactionNew'));

const InvoiceList = React.lazy(() => import('./pages/invoice/InvoiceList'));
const InvoiceDetail = React.lazy(() => import('./pages/invoice/InvoiceDetail'));
const InvoiceDetailNew = React.lazy(() => import('./pages/invoice/InvoiceDetailNew'));
const InvoiceEdit = React.lazy(() => import('./pages/invoice/InvoiceEdit'));
const InvoiceNew = React.lazy(() => import('./pages/invoice/InvoiceNew'));

const InvoiceCancelledList = React.lazy(() => import('./pages/invoice-cancelled/InvoiceCancelledList'));
const InvoiceCancelledEdit = React.lazy(() => import('./pages/invoice-cancelled/InvoiceCancelledEdit'));

const ServiceMovingJournalList = React.lazy(() => import('./pages/service-moving-journal/ServiceMovingJournalList'));
const ServiceMovingJournalEdit = React.lazy(() => import('./pages/service-moving-journal/ServiceMovingJournalEdit'));
const ServiceMovingJournalNew = React.lazy(() => import('./pages/service-moving-journal/ServiceMovingJournalNew'));
const FCMMessage = React.lazy(() => import('./pages/FCMMessage'));

export const privateTabRoutes = [
  { path: '/', exact: true, component: React.lazy(() => import('./pages/DashboardPage')) },
  { path: '/admin', exact: false, component: React.lazy(() => import('./components/Layout/AdminLayout')) },
  { path: '/setup', exact: false, component: React.lazy(() => import('./components/Layout/SetupLayout')) },
  { path: '/general', exact: false, component: React.lazy(() => import('./components/Layout/GeneralLayout')) },
  { path: '/catalog', exact: false, component: React.lazy(() => import('./pages/product/CatalogPage')) },
  {
    path: '/purchase-order-list',
    exact: false,
    component: React.lazy(() => import('./pages/purchase-order/PurchaseOrderList')),
  },
  {
    path: '/wallet',
    exact: false,
    component: React.lazy(() => import('./pages/wallet/WalletEdit')),
  },
  {
    path: '/purchase-order-new',
    exact: true,
    component: React.lazy(() => import('./pages/purchase-order/PurchaseOrderNew')),
  },
  {
    path: '/purchase-order-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/purchase-order/PurchaseOrderEdit')),
  },
  {
    path: '/purchase-order-detail/:id',
    exact: true,
    component: React.lazy(() => import('./pages/purchase-order/PurchaseOrderDetail')),
  },
];

export const publicRoutes = [
  { path: '/sign-in', exact: true, component: SignInPage },
  { path: '/sign-up', exact: true, component: SignUpPage },
  // { path: '/catalog', exact: false, component: CatalogPage }, 
  {
    path: '/distributor-sign-up',
    exact: true,
    component: DistributorSignUpPage,
  },
  { path: '/agent-sign-up', exact: true, component: AgentSignUpPage },
  { path: '/confirm-email', exact: true, component: ConfirmEmailPage },
  { path: '/forgot-password', exact: true, component: ForgotPasswordPage },
  { path: '/reset-password', exact: true, component: ResetPasswordPage },
  { path: '/pdfviewer', exact: true, component: PdfViewerPage },
];

export const privateRoutes = [
  { path: '/profile', exact: true, component: React.lazy(() => import('./pages/account/ProfilePage')) },
  { path: '/invitations', exact: true, component: React.lazy(() => import('./pages/account/InvitationList')) },
];

export const adminRoutes = [
  {
    path: '/users',
    exact: true,
    component: React.lazy(() => import('./pages/user/UserList')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/user-new',
    exact: true,
    component: React.lazy(() => import('./pages/user/UserNew')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/user-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/user/UserEdit')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/user-detail/:id',
    exact: true,
    component: React.lazy(() => import('./pages/user/UserDetail')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/roles',
    exact: true,
    component: React.lazy(() => import('./pages/account/RoleListPage')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/permissions',
    exact: true,
    component: React.lazy(() => import('../views/pages/permisison/RolePermissions')),
  },
  {
    path: '/customer-group-permissions',
    exact: true,
    component: React.lazy(() => import('../views/pages/permisison/CustomerGroupPermissions')),
  },
  {
    path: '/logs',
    exact: true,
    component: React.lazy(() => import('./pages/log/LogList')),
  },
  {
    path: '/log-detail/:id',
    exact: true,
    component: React.lazy(() => import('./pages/log/LogDetail')),
  },
];

export const generalRoutes = [
  {
    path: '/company-list',
    exact: true,
    component: React.lazy(() => import('./pages/company/CompanyList')),
    roles: [Roles.DmsAdmin, Roles.CompanyAdmin],
  },
  {
    path: '/company-new',
    exact: true,
    component: React.lazy(() => import('./pages/company/CompanyNew')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/company-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/company/CompanyEdit')),
    roles: [Roles.DmsAdmin],
  },
  { path: '/company-detail', exact: true, component: React.lazy(() => import('./pages/company/CompanyDetail')) },
  {
    path: '/setting-list',
    exact: true,
    component: React.lazy(() => import('./pages/setting/SettingList')),
  },
  {
    path: '/setting-new',
    exact: true,
    component: React.lazy(() => import('./pages/setting/SettingNew')),
  },
  {
    path: '/setting-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/setting/SettingEdit')),
  },
  {
    path: '/currency-list',
    exact: true,
    component: React.lazy(() => import('./pages/currency/CurrencyList')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/currency-new',
    exact: true,
    component: React.lazy(() => import('./pages/currency/CurrencyNew')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/currency-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/currency/CurrencyEdit')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/country-list',
    exact: true,
    component: React.lazy(() => import('./pages/country/CountryList')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/country-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/country/CountryEdit')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/bank-list',
    exact: true,
    component: React.lazy(() => import('./pages/bank/BankList')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/bank-new',
    exact: true,
    component: React.lazy(() => import('./pages/bank/BankNew')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/bank-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/bank/BankEdit')),
    roles: [Roles.DmsAdmin],
  },
  {
    path: '/fcm-message',
    exact: true,
    component: FCMMessage,
  },
  {
    path: '/uom-list',
    exact: true,
    component: React.lazy(() => import('./pages/uom/UomList')),
    roles: [Roles.DmsAdmin],
  },
  { path: '/uom-new', exact: true, component: React.lazy(() => import('./pages/uom/UomNew')), roles: [Roles.DmsAdmin] },
  {
    path: '/uom-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/uom/UomEdit')),
    roles: [Roles.DmsAdmin],
  },
  { path: '/notification-list', exact: true, component: React.lazy(() => import('./pages/notification/NotificationList')) },
  { path: '/tax-code-list', exact: true, component: React.lazy(() => import('./pages/tax-code/TaxCodeList')) },
  { path: '/tax-code-new', exact: true, component: React.lazy(() => import('./pages/tax-code/TaxCodeNew')) },
  { path: '/tax-code-edit/:id', exact: true, component: React.lazy(() => import('./pages/tax-code/TaxCodeEdit')) },
  { path: '/tax-group-list', exact: true, component: React.lazy(() => import('./pages/tax-group/TaxGroupList')) },
  { path: '/tax-group-new', exact: true, component: React.lazy(() => import('./pages/tax-group/TaxGroupNew')) },
  { path: '/tax-group-edit/:id', exact: true, component: React.lazy(() => import('./pages/tax-group/TaxGroupEdit')) },
  { path: '/item-tax-group-list', exact: true, component: ItemTaxGroupList },
  { path: '/item-tax-group-new', exact: true, component: ItemTaxGroupNew },
  {
    path: '/item-tax-group-edit/:id',
    exact: true,
    component: ItemTaxGroupEdit,
  },
];

export const setupRoutes = [
  {
    path: '/frewie-coupon-transaction-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-coupon-transaction/FrewieCouponTransactionList')),
  },
  {
    path: '/frewie-coupon-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-coupon/FrewieCouponList')),
  },
  {
    path: '/frewie-coupon-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-coupon/FrewieCouponNew')),
  },
  {
    path: '/frewie-coupon-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-coupon/FrewieCouponEdit')),
  },
  {
    path: '/promotion-list',
    exact: true,
    component: React.lazy(() => import('./pages/promotion/PromotionList')),
  },
  {
    path: '/promotion-new',
    exact: true,
    component: React.lazy(() => import('./pages/promotion/PromotionNew')),
  },
  {
    path: '/promotion-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/promotion/PromotionEdit')),
  },
  {
    path: '/frewie-platform-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-platform/FrewiePlatformList')),
  },
  {
    path: '/frewie-platform-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-platform/FrewiePlatformNew')),
  },
  {
    path: '/frewie-platform-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-platform/FrewiePlatformEdit')),
  },
  {
    path: '/recommend-list',
    exact: true,
    component: React.lazy(() => import('./pages/recommend/RecommendList')),
  },
  {
    path: '/recommend-new',
    exact: true,
    component: React.lazy(() => import('./pages/recommend/RecommendNew')),
  },
  {
    path: '/recommend-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/recommend/RecommendEdit')),
  },
  {
    path: '/offer-group-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/offer-group/OfferGroupList')),
  },
  {
    path: '/offer-group-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/offer-group/OfferGroupNew')),
  },
  {
    path: '/offer-group-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/offer-group/OfferGroupEdit')),
  },
  {
    path: '/best-selling-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/best-selling/BestSellingList')),
  },
  {
    path: '/best-selling-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/best-selling/BestSellingNew')),
  },
  {
    path: '/best-selling-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/best-selling/BestSellingEdit')),
  },
  {
    path: '/more-directory-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/more-directory/MoreDirectoryList')),
  },
  {
    path: '/more-directory-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/more-directory/MoreDirectoryNew')),
  },
  {
    path: '/more-directory-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/more-directory/MoreDirectoryEdit')),
  },
  {
    path: '/gift-background-image-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/gift-background-image/GiftBackgroundImageList')),
  },
  {
    path: '/gift-background-image-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/gift-background-image/GiftBackgroundImageNew')),
  },
  {
    path: '/gift-background-image-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/gift-background-image/GiftBackgroundImageEdit')),
  },
  {
    path: '/partner-offer-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/partner-offer/PartnerOfferList')),
  },
  {
    path: '/partner-offer-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/partner-offer/PartnerOfferNew')),
  },
  {
    path: '/partner-offer-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/partner-offer/PartnerOfferEdit')),
  },
  {
    path: '/usage-report-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-report/UsageReportList')),
  },
  {
    path: '/wallet-list',
    exact: true,
    component: React.lazy(() => import('./pages/wallet/WalletList')),
  },
  {
    path: '/wallet-transaction-list',
    exact: true,
    component: React.lazy(() => import('./pages/wallet/WalletTransactionList')),
  },
  {
    path: '/payment-term-list',
    exact: true,
    component: React.lazy(() => import('./pages/payment-term/PaymentTermList')),
  },
  {
    path: '/payment-term-new',
    exact: true,
    component: React.lazy(() => import('./pages/payment-term/PaymentTermNew')),
  },
  {
    path: '/payment-term-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/payment-term/PaymentTermEdit')),
  },
  {
    path: '/customer-group-list',
    exact: true,
    component: React.lazy(() => import('./pages/customer-group/CustomerGroupList')),
  },
  { path: '/customer-group-new', exact: true, component: CustomerGroupNew },
  {
    path: '/customer-group-edit/:id',
    exact: true,
    component: CustomerGroupEdit,
  },
  {
    path: '/consumer-affiliate-registration-list',
    exact: true,
    component: React.lazy(() => import('./pages/consumer-affiliate-registration/ConsumerAffiliateRegistrationList')),
  },
  {
    path: '/consumer-affiliate-registration-edit/:id',
    exact: true,
    component: ConsumerRegistrationEdit,
  },
  {
    path: '/consumer-goal-edit/:id',
    exact: true,
    component: ConsumerGoalEdit,
  },
  {
    path: '/consumer-goal-list',
    exact: true,
    component: React.lazy(() => import('./pages/consumer-goal/ConsumerGoalList')),
  },
  {
    path: '/consumer-group-list',
    exact: true,
    component: React.lazy(() => import('./pages/consumer-group/ConsumerGroupList')),
  },
  { path: '/consumer-group-new', exact: true, component: ConsumerGroupNew },
  {
    path: '/consumer-group-edit/:id',
    exact: true,
    component: ConsumerGroupEdit,
  },
  {
    path: '/consumer-list',
    exact: true,
    component: React.lazy(() => import('./pages/consumer/ConsumerList')),
  },
  {
    path: '/consumer-affiliate-list',
    exact: true,
    component: React.lazy(() => import('./pages/consumer/ConsumerAffiliateList')),
  },
  {
    path: '/consumer-commission-list',
    exact: true,
    component: React.lazy(() => import('./pages/consumer/ConsumerCommissionList')),
  },
  {
    path: '/consumer-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/consumer/ConsumerEdit')),
  },
  {
    path: '/usage-notification-list',
    exact: true,
    component: React.lazy(() => import('./pages/usage-notification/UsageNotificationList')),
  },
  {
    path: '/usage-notification-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/usage-notification/UsageNotificationEdit')),
  },
  {
    path: '/customer-list',
    exact: true,
    component: React.lazy(() => import('./pages/customer/CustomerList')),
  },
  {
    path: '/customer-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/customer/CustomerEdit')),
  },
  {
    path: '/customer-new',
    exact: true,
    component: React.lazy(() => import('./pages/customer/CustomerNew')),
  },
  { path: '/cost-type-setup-list', exact: true, component: CostTypeSetupList },
  { path: '/cost-type-setup-new', exact: true, component: CostTypeSetupNew },
  {
    path: '/cost-type-setup-edit/:id',
    exact: true,
    component: CostTypeSetupEdit,
  },
  { path: '/cost-setup-list', exact: true, component: CostSetupList },
  { path: '/cost-setup-new', exact: true, component: CostSetupNew },
  { path: '/cost-setup-edit/:id', exact: true, component: CostSetupEdit },
  { path: '/dimension-setup-list', exact: true, component: DimensionSetupList },
  { path: '/dimension-setup-new', exact: true, component: DimensionSetupNew },
  {
    path: '/dimension-setup-edit/:id',
    exact: true,
    component: DimensionSetupEdit,
  },
  { path: '/warranty-type-list', exact: true, component: WarrantyTypeList },
  { path: '/warranty-type-new', exact: true, component: WarrantyTypeNew },
  { path: '/warranty-type-edit/:id', exact: true, component: WarrantyTypeEdit },
  { path: '/warranty-list', exact: true, component: WarrantyList },
  { path: '/warranty-new', exact: true, component: WarrantyNew },
  { path: '/warranty-edit/:id', exact: true, component: WarrantyEdit },
  { path: '/location-setup', exact: true, component: LocationSetup },
  { path: '/location-unit-list', exact: true, component: LocationUnitList },
  { path: '/location-unit-new', exact: true, component: LocationUnitNew },
  { path: '/location-unit-edit/:id', exact: true, component: LocationUnitEdit },
  { path: '/purchase-setup-list', exact: true, component: PurchaseSetupList },
  {
    path: '/purchase-setup-edit/:id',
    exact: true,
    component: PurchaseSetupEdit,
  },
  { path: '/purchase-order-list', exact: true, component: PurchaseOrderList },
  { path: '/purchase-order-new', exact: true, component: PurchaseOrderNew },
  {
    path: '/purchase-order-edit/:id',
    exact: true,
    component: PurchaseOrderEdit,
  },
  {
    path: '/received-order-list',
    exact: true,
    component: React.lazy(() => import('./pages/purchase-order/ReceivedOrderList')),
  },
  {
    path: '/received-order-detail/:id',
    exact: true,
    component: React.lazy(() => import('./pages/purchase-order/ReceivedOrderDetail')),
  },
  {
    path: '/sale-order-list',
    exact: true,
    component: React.lazy(() => import('./pages/sale-order/SaleOrderList')),
  },
  {
    path: '/sale-order-new',
    exact: true,
    component: React.lazy(() => import('./pages/sale-order/SaleOrderNew')),
  },
  {
    path: '/sale-order-detail/:id',
    exact: true,
    component: React.lazy(() => import('./pages/sale-order/SaleOrderEdit')),
  },
  { path: '/agent-list', exact: true, component: AgentList },
  { path: '/agent-detail/:id', exact: true, component: AgentEdit },
  { path: '/agent-hierarchy', exact: true, component: AgentHierarchy },
  {
    path: '/location-sorting-list',
    exact: true,
    component: LocationSortingList,
  },
  { path: '/location-sorting-new', exact: true, component: LocationSortingNew },
  {
    path: '/location-sorting-edit/:id',
    exact: true,
    component: LocationSortingEdit,
  },
  {
    path: '/product-category-list',
    exact: true,
    component: ProductCategoryList,
  },
  { path: '/product-category-new', exact: true, component: ProductCategoryNew },
  {
    path: '/product-category-edit/:id',
    exact: true,
    component: ProductCategoryEdit,
  },
  { path: '/product-type-list', exact: true, component: ProductTypeList },
  { path: '/product-type-new', exact: true, component: ProductTypeNew },
  { path: '/product-type-edit/:id', exact: true, component: ProductTypeEdit },
  { path: '/frewie-plan-list', exact: true, component: FrewiePlanList },
  { path: '/frewie-plan-new', exact: true, component: FrewiePlanNew },
  { path: '/frewie-plan-edit/:id', exact: true, component: FrewiePlanEdit },
  {
    path: '/frewie-serial-number-list',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-serial-number/FrewieSerialNumberList')),
  },
  {
    path: '/spark-package-import',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-package-plan/SparkPackageImport')),
  },
  {
    path: '/frewie-serial-number-import',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-serial-number/FrewieSerialNumberImport')),
  },
  {
    path: '/frewie-serial-number-new',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-serial-number/FrewieSerialNumberNew')),
  },
  {
    path: '/frewie-serial-number-edit/:id',
    exact: true,
    component: React.lazy(() => import('./pages/frewie/frewie-serial-number/FrewieSerialNumberEdit')),
  },
  { path: '/location-list', exact: true, component: LocationList },
  { path: '/location-edit/:id', exact: true, component: LocationEdit },
  { path: '/brand-list', exact: true, component: BrandList },
  { path: '/brand-new', exact: true, component: BrandNew },
  { path: '/brand-edit/:id', exact: true, component: BrandEdit },
  { path: '/product-list', exact: true, component: React.lazy(() => import('./pages/product/ProductPage')) },
  { path: '/product-detail/:id', exact: true, component: React.lazy(() => import('./pages/product/ProductDetail')) },
  { path: '/product-new', exact: true, component: ProductNew },
  { path: '/product-edit/:id', exact: true, component: ProductEdit },
  {
    path: '/movement-journal-list',
    exact: true,
    component: MovementJournalList,
  },
  { path: '/movement-journal-new', exact: true, component: MovementJournalNew },
  {
    path: '/movement-journal-adjust/:id',
    exact: true,
    component: MovementJournalAdjust,
  },
  {
    path: '/movement-journal-detail/:id',
    exact: true,
    component: MovementJournalDetail,
  },
  {
    path: '/timeslot-list',
    exact: true,
    component: TimeslotList,
  },
  {
    path: '/timeslot-new',
    exact: true,
    component: TimeslotNew,
  },
  {
    path: '/timeslot-edit/:id',
    exact: true,
    component: TimeslotEdit,
  },
  {
    path: '/holiday-list',
    exact: true,
    component: HolidayList,
  },
  {
    path: '/holiday-new',
    exact: true,
    component: HolidayNew,
  },
  {
    path: '/holiday-edit/:id',
    exact: true,
    component: HolidayEdit,
  },
  {
    path: '/appointment-config',
    exact: true,
    component: AppointmentConfig,
  },
  {
    path: '/appointment-list',
    exact: true,
    component: AppointmentList,
  },
  {
    path: '/appointment-new',
    exact: true,
    component: AppointmentNew,
  },
  {
    path: '/appointment-edit/:id',
    exact: true,
    component: AppointmentEdit,
  },
  {
    path: '/membership-list',
    exact: true,
    component: MembershipList,
  },
  {
    path: '/membership-new',
    exact: true,
    component: MembershipNew,
  },
  {
    path: '/membership-edit/:id',
    exact: true,
    component: MembershipEdit,
  },
  {
    path: '/transaction-list',
    exact: true,
    component: TransactionList,
  },
  {
    path: '/transaction-new',
    exact: true,
    component: TransactionNew,
  },
  {
    path: '/transaction-edit/:id',
    exact: true,
    component: TransactionEdit,
  },
  {
    path: '/invoice-list',
    exact: true,
    component: InvoiceList,
  },
  {
    path: '/invoice-detail',
    exact: true,
    component: InvoiceDetail,
  },
  {
    path: '/invoice-detail-new',
    exact: true,
    component: InvoiceDetailNew,
  },
  {
    path: '/invoice-new',
    exact: true,
    component: InvoiceNew,
  },
  {
    path: '/invoice-edit/:id',
    exact: true,
    component: InvoiceEdit,
  },
  {
    path: '/invoice-cancelled-list',
    exact: true,
    component: InvoiceCancelledList,
  },
  {
    path: '/invoice-cancelled-edit/:id',
    exact: true,
    component: InvoiceCancelledEdit,
  },
  {
    path: '/service-moving-journal-list',
    exact: true,
    component: ServiceMovingJournalList,
  },
  {
    path: '/service-moving-journal-new',
    exact: true,
    component: ServiceMovingJournalNew,
  },
  {
    path: '/service-moving-journal-edit/:id',
    exact: true,
    component: ServiceMovingJournalEdit,
  },
];
